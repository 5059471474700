import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo'; 
import PageHeader from '../components/page-header';

import bg from '../images/notes/nota1a.jpg';

const Nota1 = () => {
  return (
    <Layout lightheader>
      <SEO title="La importancia de la emoción para tener hijos resilientes" />
      <div className="page about-page">
        <PageHeader bg={bg} />
        <div className="wrapper content">
          <h1>La importancia de la emoción para tener hijos resilientes</h1>
          <div className="row">
            <div className="col">
              <p>
                Vivimos en una sociedad en la que continuamente proliferan mensajes sobre la necesidad e incluso obligación de ser feliz, como si de una máxima por la mera condición de ser humano se tratase. Esto se hace patente en el día a día en forma de conocidos spots publicitarios: “Estás aquí para ser feliz, refresca tu salud emocional” y actuales e influyentes marcas cuya presentación introduce de forma directa tal principio: “… la tienda de productos felices para alegrar al personal”. Sin embargo, saber expresar y entender las emociones (todas y cada una de ellas, no solo la felicidad) es imprescindible para ser resiliente y saber afrontar las vicisitudes de la vida.
              </p>
              <p>
                Pero, ¿nos encontramos ante un acercamiento real a la sociedad o ante una especie de invasión de sonrisas, amor y compañerismo injustificado y un tanto exagerado? Desde una perspectiva personal basada en la práctica clínica, puedo afirmar, sin lugar a dudas, que tal derroche de positividad genera un conflicto interior de una intensidad y consecuencias notablemente superior al bien que tienen como objeto.
              </p>
              <p>
                Bien, detengámonos y analicemos qué promueve realmente esta nueva forma de relacionarnos con el mundo. Para ello, es necesario introducirnos en el mundo de las emociones.
              </p>
              <h3>¿Qué son las emociones?</h3>
              <p>
                Se trata de aquellas respuestas fisiológicas automáticas que el organismo emite de manera involuntaria (y a las que posteriormente otorgamos de un significante) con el fin de advertirnos, alertarnos de que algo está ocurriendo y que bien puede estar afectando nuestra relación con el medio, bien puede estar propiciando un contacto adecuado. O, como dirían los niños: “las emociones son las caritas que ponemos cuando nos pasan cosas”. Si valoramos por un momento las emociones más comúnmente conocidas y experimentadas por cada uno de nosotros, o si simplemente nombramos las primeras que se nos vienen a la mente, alegría, tristeza, ira, asco, miedo y sorpresa serían las elegidas. Estas son las denominadas emociones primarias, las que hacen referencia a los actos de expresión innatos del organismo y, por lo tanto, compartidos por todos desde el nacimiento: son básicas y universales.
              </p>
              <p>
                Es notable advertir como entre estas emociones, únicamente una de ellas hace referencia a una vivencia agradable, la alegría, mientras que el resto declinan la balanza hacia experiencias desagradables, oscilando la sorpresa entre ambas polaridades. Como se ha mencionado, el hecho de que acompañen a todo ser vivo desde el momento de su nacimiento evidencia su <b>necesidad en el desarrollo del individuo en pro de la supervivencia</b>.
              </p>
              <p>
                De este modo, cobra vital relevancia la validación emocional de los niños. Con esto nos referimos a la importancia de <b>permitir la expresión de todo aquello que el niño sienta necesidad de exteriorizar</b>, incluyendo los momentos de ira y tristeza que tanto se empeñan, nos empeñamos, en limitar. Validar implica atender sus necesidades desde la escucha activa, empatía y apoyo en un contexto seguro, de forma que la gestión emocional vaya tornando desde una co-regulación (regulación y gestión a través del otro) a una auto-regulación tanto interna como externa, a nivel conductual.
              </p>
              <p>
                Como padres, ¿cómo puede llevarse a cabo tal validación? A continuación exponemos una serie de sencillas pautas:
              </p>
              <h3>Comunicación</h3>
              <p>
                Promover un <b>entorno comunicativo</b> en el que el niño observe como el resto de integrantes se ofrece apoyo y protección mutua. Para ello, es conveniente que, en momentos de reunión familiar y un ambiente neutro y distendido, papá y mamá motiven compartir los momentos del día más destacados, así como las sensaciones asociadas. Se trata pues de otorgar de un significado emocional apoyado en una valoración afectiva a los sucesos cotidianos.
              </p>
              <h3>Aceptar todas las emociones</h3>
              <p>
                Evitar dar un espacio mayor a vivencias que conlleven alegría, pues el mensaje implícito de esto es, una vez más, castigador en cuanto al resto de emociones: “no está permitido sufrir”. Tal mensaje conlleva, entre otra sintomatología, frustración y bloqueos internos externalizados en forma de conducta disruptiva y aspectos más somáticos del tipo cefaleas, retroceso en el control de esfínteres o erupciones cutáneas.
              </p>
              <h3>Dar el espacio a expresarlas</h3>
              <p>
                Mantener al menor en contacto con la emoción, es decir, permitir su experiencia más plena en un intento de acercarle al mensaje que tal vivencia trata de advertirnos. No se trata de hacer hincapié en el carácter doloroso del acontecimiento, sino en la aceptación y asunción de determinadas sensaciones, dándole un espacio en el que comprobar como igual que aparecen de forma automática, desaparecerán por su propio pie.
              </p>
              <h3>Comprender la diversidad de reacción</h3>
              <p>
                Comprender las múltiples formas de reaccionar ante un mismo evento, tantas como personas lo experimenten. Recordemos que una misma situación despertará valoraciones subjetivas basadas en la historia personal de cada uno. Desde un plano emocional, toda vivencia será aceptada sin lugar a juicio o interpretación ajena, sin confundir esto con permitir todo tipo de gestión a nivel conductual.
              </p>
              <p>
                En suma, no se trata de obligar al menor a exteriorizar su activo mundo interno como una norma más a asumir y de carácter obligatorio, sino de favorecer y normalizar la vivencia y expresión de todo aquello que necesite externalizar.
              </p>
              <p>
                Un primer acercamiento al complejo mundo de las emociones puede hacerse mediante la conocida película Del revés cuyos personajes representan cinco de las mencionadas emociones primarias, las cuales han de actuar en equipo en beneficio de la protagonista, una niña que necesita adaptarse a una nueva situación de la manera más exitosa posible, dando espacio a vivencias desagradables en tal búsqueda de estabilidad y bienestar.
              </p>
              <p>
                Próximamente hablaremos de cada una de estas emociones de forma aislada, no obstante y para comenzar a llevar a cabo este importante proceso en el desarrollo de los niños, podemos apoyarnos en los mencionados personajes. Una buena práctica podría ser colocar sus imágenes repartidas en su habitación, ofreciendo a la par el derecho a un espacio más íntimo, con las pautas de detenerse todas las noches frente a cada una de las imágenes meditando y, si así lo desea, compartiendo en qué momentos del día se le han puesto esas caritas y por qué.
              </p>
              <p>
              <br/><br/>
              Bibliografía <br/>
              <b>Vega Seoánez, A. (2020)</b>. <i>El mundo sapos y princesas. </i>
              <br/>Obtenido de <a target="_blank" href="https://saposyprincesas.elmundo.es/consejos/psicologia-infantil/entender-las-emociones-ninos-resilientes/">https://saposyprincesas.elmundo.es/consejos/psicologia-infantil/entender-las-emociones-ninos-resilientes/</a>

              </p>
            </div>
          </div>
          <hr/>
        </div>
      </div>
    </Layout>
  )
}

export default Nota1;